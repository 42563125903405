import React from 'react'
import { handleSetUpgradeStrategy } from '../UpgradeClusterPage'
import CheckboxField from 'core/components/validatedForm/CheckboxField'

const SequentialClusterUpgradeField = ({ wizardContext, setWizardContext }) => (
  <CheckboxField
    id="sequentialClusterUpgrade"
    label="Sequential"
    disabled={!!wizardContext.upgradingTo}
    onChange={(value) =>
      setWizardContext(handleSetUpgradeStrategy(value, 'sequentialClusterUpgrade'))
    }
    value={wizardContext?.sequentialClusterUpgrade}
    info="The worker nodes will be sequentially upgraded, one node after the other."
  />
)

export default SequentialClusterUpgradeField
