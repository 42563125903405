import React from 'react'
import TextField from 'core/components/validatedForm/TextField'
import { compareVersions } from 'k8s/util/helpers'
import CheckboxField from 'core/components/validatedForm/CheckboxField'

export default function AwsAutoscalerParamFields({
  setFieldValue,
  addonVersion = undefined,
  values,
}) {
  const isUpdatedVersion =
    addonVersion === undefined || compareVersions(addonVersion, '1.14.8') >= 0

  return (
    <>
      {isUpdatedVersion && (
        <CheckboxField
          id="enableAddonResizer"
          label="Enable Addon Resizer"
          onChange={(value) => {
            setFieldValue('enableAddonResizer')(value)
          }}
        />
      )}
      {!isUpdatedVersion ||
        (!values?.enableAddonResizer && (
          <>
            <TextField
              key="cpuRequest"
              id="cpuRequest"
              label="CPU Request (m)"
              initialValue={100}
              onChange={setFieldValue('cpuRequest')}
              type="number"
              min="0"
              required
            />
            <TextField
              key="cpuLimit"
              id="cpuLimit"
              label="CPU Limit (m)"
              initialValue={200}
              onChange={setFieldValue('cpuLimit')}
              type="number"
              min="0"
              required
            />
            <TextField
              key="memRequest"
              id="memRequest"
              label="Memory Limit (Mi)"
              initialValue={300}
              onChange={setFieldValue('memRequest')}
              type="number"
              min="0"
              required
            />
            <TextField
              key="memLimit"
              id="memLimit"
              label="Memory Limit (Mi)"
              initialValue={600}
              onChange={setFieldValue('memLimit')}
              type="number"
              min="0"
              required
            />
          </>
        ))}
      {isUpdatedVersion && values?.enableAddonResizer && (
        <>
          <TextField
            key="cpuRequestLimit"
            id="cpuRequestLimit"
            label="CPU Request Limit (m)"
            initialValue={150}
            onChange={setFieldValue('cpuRequestLimit')}
            type="number"
            min="0"
            required
          />
          <TextField
            key="cpuExtra"
            id="cpuExtra"
            label="CPU Extra (m)"
            initialValue={0.5}
            onChange={setFieldValue('cpuExtra')}
            type="number"
            min="0"
            required
          />
          <TextField
            key="memRequestLimit"
            id="memRequestLimit"
            label="Memory Request Limit (Mi)"
            initialValue={300}
            onChange={setFieldValue('memRequestLimit')}
            type="number"
            min="0"
            required
          />
          <TextField
            key="memExtra"
            id="memExtra"
            label="Memory Extra (Mi)"
            initialValue={10}
            onChange={setFieldValue('memExtra')}
            type="number"
            min="0"
            required
          />
          <TextField
            key="percentThreshold"
            id="percentThreshold"
            label="Percent Threshold"
            initialValue={5}
            onChange={setFieldValue('percentThreshold')}
            type="number"
            min="0"
            required
          />
          <TextField
            key="minClusterSize"
            id="minClusterSize"
            label="Min Cluster Size"
            initialValue={2}
            onChange={setFieldValue('minClusterSize')}
            type="number"
            min="0"
            required
          />
        </>
      )}
    </>
  )
}
