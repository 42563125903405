import Plugin from 'core/plugins/plugin'
import { AppPlugins } from 'app/constants'
import NotificationsPage from 'core/components/notifications/NotificationsPage'
import HelpPage from 'k8s/components/common/HelpPage'
import DashboardPage from './components/dashboard/Insights'

import { infraLogo } from './logo'
import { isDecco } from 'core/utils/helpers'
import ClustersOverviewPage from 'app/plugins/infrastructure/components/clusters/ClustersOverviewListPage'
import ImportedClustersListPage from 'app/plugins/infrastructure/components/importedClusters/ImportedClustersListPage'
import CapiClustersListPage from 'app/plugins/infrastructure/components/clusters/capi/CapiClustersListPage'
import ClustersIndexPage from 'app/plugins/infrastructure/components/clusters/ClustersIndexPage'
import AddClusterPage from './components/clusters/AddClusterPage'
import AddAwsClusterPage from './components/clusters/aws/AddAwsClusterPage'
import AddAzureClusterPage from './components/clusters/azure/AddAzureClusterPage'
import AddBareOsClusterPage from './components/clusters/bareos/AddBareOsClusterPage'
import CapiClusterDetailsPage from './components/clusters/capi/details/CapiClusterDetailsPage'
import CapiNodeGroupsDetails from './components/clusters/capi/details/node-groups/CapiNodeGroupsDetails'
import ClusterDetailsPage from './components/clusters/ClusterDetailsPage'
import EditClusterPage from './components/clusters/EditClusterPage'
import ImportAKSClusterPage from './components/clusters/import/ImportAKSClusterPage'
import ImportClusterPage from './components/clusters/import/ImportClusterPage'
import ImportEKSClusterPage from './components/clusters/import/ImportEKSClusterPage'
import ImportGKEClusterPage from './components/clusters/import/ImportGKEClusterPage'
import EditImportedClusterPage from './components/importedClusters/edit/EditImportedClusterPage'
import ImportedClusterDetailsPage from './components/importedClusters/imported-cluster-details'
import NodeDetailsPage from './components/nodes/NodeDetailsPage'
import NodesIndexPage from './components/nodes/NodesIndexPage'
import OnboardNewNodePage from './components/nodes/OnboardNewNodePage'
import OnboardAirgappedNodePage from './components/nodes/OnboardAirgappedNodePage'
import AwsCloudProvidersListPage from './components/cloudProviders/aws/AwsCloudProvidersListPage'
import AzureCloudProvidersListPage from './components/cloudProviders/azure/AzureCloudProvidersListPage'
import GoogleCloudProvidersListPage from './components/cloudProviders/google/GoogleCloudProvidersListPage'
import AddCloudProviderPage from './components/cloudProviders/AddCloudProviderPage'
import StorageDetailsPage from 'k8s/components/storage/details/StorageClassDetailsPage'
import RbacProfilesListPage from 'k8s/components/rbac/profiles/RbacProfilesListPage'
import CreateRbacProfile from 'k8s/components/rbac/profiles/create'
import EditRbacProfile from 'k8s/components/rbac/profiles/edit'
import DeleteProfileBindingsPage from 'k8s/components/rbac/profiles/DeleteProfileBindings'
import DeployRbacProfilePage from 'k8s/components/rbac/profiles/deploy/DeployRbacProfilePage'
import DriftAnalysisPage from 'k8s/components/rbac/profiles/drift/DriftAnalysisPage'
import ImportDriftAnalysisPage from 'k8s/components/rbac/profiles/drift/ImportDriftAnalysisPage'
import ViewDriftAnalysisPage from 'k8s/components/rbac/profiles/drift/ViewDriftAnalysisPage'
import DriftAnalysisListPage from 'k8s/components/rbac/profiles/DriftAnalysisListPage'
import ApiServicesPage from 'k8s/components/apiAccess/ApiServicesPage'
import KubeConfigListPage from 'k8s/components/apiAccess/kubeConfig/KubeConfigListPage'
import TerraformListPage from 'k8s/components/apiAccess/TerraformListPage'
import AlarmOverviewPage from 'k8s/components/monitoring/AlarmOverviewPage'
import CapiClusterUpgradePage from './components/clusters/capi/upgrade/CapiClusterUpgradePage'
import EksNodesListPage from './components/nodes/EksNodesListPage'
import EksNodeInfo from './components/nodes/EksNodeInfo'
import ManagedClustersListPage from './components/clusters/ManagedClustersListPage'

const infrastructurePlugin = new Plugin(
  AppPlugins.Infrastructure,
  'Infrastructure',
  '/ui/infrastructure',
  infraLogo,
)

infrastructurePlugin.registerRoutes([
  {
    name: 'Dashboard',
    link: { path: '/dashboard/overview', exact: true, default: true },
    component: DashboardPage,
  },
  {
    name: 'Alarms',
    link: { path: '/dashboard/alarms', exact: true },
    component: AlarmOverviewPage,
  },
  {
    name: 'Notifications',
    link: { path: '/notifications/:notificationType/:id?', exact: true },
    component: NotificationsPage,
  },
  {
    name: 'Clusters Overview',
    link: { path: '/clusters/overview/(qbert||capi)?/:id?/(upgrade)?', exact: true },
    component: ClustersOverviewPage,
  },
  {
    name: 'Managed Clusters',
    link: {
      path: '/clusters/managed/(qbert)?/:id?/(upgrade||scale-masters||scale-workers)?',
      exact: true,
    },
    component: ManagedClustersListPage,
  },
  {
    name: 'Managed CAPI Cluster Upgrade',
    link: { path: '/clusters/managed/capi/:id/upgrade', exact: true },
    component: CapiClusterUpgradePage,
  },
  {
    name: 'Managed CAPI Cluster Details',
    link: { path: '/clusters/managed/capi/:id/:tab?', exact: true },
    component: CapiClusterDetailsPage,
  },
  {
    name: 'Managed CAPI Cluster Details',
    link: { path: '/clusters/managed/capi/:id/:tab/upgrade-progress/:jobId', exact: true },
    component: CapiClusterDetailsPage,
  },
  // Separate route for this, bc adding the (add) to above one
  // causes a weird undefined to show up in the url
  {
    name: 'Managed CAPI Cluster Details',
    link: { path: '/clusters/managed/capi/:id/:tab?/add', exact: true },
    component: CapiClusterDetailsPage,
  },
  {
    name: 'Managed CAPI Node Group Details',
    link: { path: '/clusters/managed/capi/:id/:tab/:name', exact: true },
    component: CapiNodeGroupsDetails,
  },
  {
    name: 'Imported Clusters',
    link: { path: '/clusters/imported', exact: true },
    component: ImportedClustersListPage,
  },
  {
    name: 'Create Cluster',
    link: { path: '/clusters/add', exact: true },
    requiredRoles: 'admin',
    component: AddClusterPage,
  },
  {
    name: 'Create AWS Cluster',
    link: { path: '/clusters/aws/add/:type', exact: true },
    requiredRoles: 'admin',
    component: AddAwsClusterPage,
  },
  {
    name: 'Create Azure Cluster',
    link: { path: '/clusters/azure/add/:type', exact: true },
    requiredRoles: 'admin',
    component: AddAzureClusterPage,
  },
  {
    name: 'Create Bare OS Cluster',
    link: { path: '/clusters/bareos/add/:platform/:type', exact: true },
    requiredRoles: 'admin',
    component: AddBareOsClusterPage,
  },
  {
    name: 'Import Cluster',
    link: { path: '/clusters/import', exact: true },
    requiredRoles: 'admin',
    component: ImportClusterPage,
  },
  {
    name: 'Import EKS Cluster',
    link: { path: '/clusters/import/eks', exact: true },
    requiredRoles: 'admin',
    component: ImportEKSClusterPage,
  },
  {
    name: 'Import AKS Cluster',
    link: { path: '/clusters/import/aks', exact: true },
    requiredRoles: 'admin',
    component: ImportAKSClusterPage,
  },
  {
    name: 'Import GKE Cluster',
    link: { path: '/clusters/import/gke', exact: true },
    requiredRoles: 'admin',
    component: ImportGKEClusterPage,
  },
  {
    name: 'Imported Cluster Details',
    link: { path: '/clusters/imported/:id/:tab', exact: true },
    requiredRoles: 'admin',
    component: ImportedClusterDetailsPage,
  },
  {
    name: 'Edit Imported Cluster',
    link: { path: '/clusters/imported/edit/:id/:tab', exact: true },
    requiredRoles: 'admin',
    component: EditImportedClusterPage,
  },
  {
    name: 'Managed Qbert Edit Cluster',
    link: { path: '/clusters/managed/qbert/edit/:id/:tab?', exact: true },
    component: EditClusterPage,
  },
  // {
  //   name: 'Managed Qbert Clusters',
  //   link: {
  //     path: '/clusters/managed/qbert/:id?/(upgrade||scale-masters||scale-workers)?',
  //     exact: true,
  //   },
  //   component: ClustersIndexPage,
  // },
  {
    name: 'Managed Qbert Cluster Details',
    link: { path: '/clusters/managed/qbert/:id/:tab?', exact: true },
    component: ClusterDetailsPage,
  },
  {
    name: 'Nodes',
    link: { path: '/nodes', exact: true },
    component: NodesIndexPage,
  },
  {
    name: 'EKS Nodes',
    link: { path: '/eks-nodes', exact: true },
    component: EksNodesListPage,
  },
  {
    name: 'EKS Node Details',
    link: { path: '/eks-nodes/:id', exact: true },
    component: EksNodeInfo,
  },
  {
    name: 'Onboard a Node',
    link: { path: '/nodes/add/:tab?', exact: true },
    component: OnboardNewNodePage,
  },
  {
    name: 'Onboard a Node',
    link: { path: '/nodes/add-airgapped/:tab?', exact: true },
    component: OnboardAirgappedNodePage,
  },
  {
    name: 'Node Details',
    link: { path: '/nodes/:id', exact: true },
    component: NodeDetailsPage,
  },
  {
    name: 'Add Cloud Provider',
    link: { path: '/cloud-providers/add', exact: true },
    requiredRoles: 'admin',
    component: AddCloudProviderPage,
  },
  {
    name: 'AWS Cloud Providers',
    link: { path: '/cloud-providers/aws/(edit)?/:id?', exact: true },
    component: AwsCloudProvidersListPage,
  },
  {
    name: 'Azure Cloud Providers',
    link: { path: '/cloud-providers/azure/(edit)?/:id?', exact: true },
    component: AzureCloudProvidersListPage,
  },
  {
    name: 'Google Cloud Providers',
    link: { path: '/cloud-providers/google/(edit)?/:id?', exact: true },
    component: GoogleCloudProvidersListPage,
  },
  // TODO why not put storage details as a page inside of cluster details?
  {
    name: 'Storage Class Details',
    link: { path: '/storage/clusters/:clusterId/storage-classes/:id/:tab?', exact: true },
    component: StorageDetailsPage,
  },
  {
    name: 'RBAC Profiles',
    requiredRoles: 'admin',
    link: { path: '/rbac-profiles/profiles', exact: true },
    requiredFeatures: isDecco,
    component: RbacProfilesListPage,
  },
  {
    name: 'Add RBAC Profile',
    requiredRoles: 'admin',
    link: { path: '/rbac-profiles/profiles/add', exact: true },
    requiredFeatures: isDecco,
    component: CreateRbacProfile,
  },
  {
    name: 'View RBAC Profile',
    requiredRoles: 'admin',
    link: { path: '/rbac-profiles/profiles/view/:id/:tab?', exact: true },
    requiredFeatures: isDecco,
    component: EditRbacProfile,
  },
  {
    name: 'Edit RBAC Profile',
    requiredRoles: 'admin',
    link: { path: '/rbac-profiles/profiles/edit/:id/:tab?', exact: true },
    requiredFeatures: isDecco,
    component: EditRbacProfile,
  },
  {
    name: 'Deploy RBAC Profile',
    requiredRoles: 'admin',
    link: { path: '/rbac-profiles/profiles/deploy/:id', exact: true },
    requiredFeatures: isDecco,
    component: DeployRbacProfilePage,
  },
  {
    name: 'Delete RBAC Profile Bindings',
    requiredRoles: 'admin',
    link: { path: '/rbac-profiles/profiles/delete-bindings/:id', exact: true },
    requiredFeatures: isDecco,
    component: DeleteProfileBindingsPage,
  },
  {
    name: 'RBAC Profiles',
    requiredRoles: 'admin',
    link: { path: '/rbac-profiles/drift', exact: true },
    requiredFeatures: isDecco,
    component: DriftAnalysisListPage,
  },
  {
    name: 'New Drift Analysis',
    link: { path: '/rbac-profiles/drift/new', exact: true },
    requiredFeatures: isDecco,
    component: DriftAnalysisPage,
  },
  {
    name: 'View Drift Analysis',
    link: { path: '/rbac-profiles/drift/view/:id/:tab?', exact: true },
    requiredFeatures: isDecco,
    component: ViewDriftAnalysisPage,
  },
  {
    name: 'Import Drift Analysis',
    link: { path: '/rbac-profiles/drift/import', exact: true },
    requiredFeatures: isDecco,
    component: ImportDriftAnalysisPage,
  },
  {
    name: 'API Access API Services',
    link: { path: '/api-access/api-services', exact: true },
    component: ApiServicesPage,
  },
  {
    name: 'API Access Kubeconfig',
    link: { path: '/api-access/kubeconfig', exact: true },
    component: KubeConfigListPage,
  },
  {
    name: 'API Access Terraform',
    link: { path: '/api-access/terraform', exact: true },
    component: TerraformListPage,
  },
  {
    name: 'Help',
    link: { path: '/help/:tab?', exact: true },
    component: HelpPage,
  },
])

infrastructurePlugin.registerNavItems([
  {
    name: 'Dashboard',
    link: { path: '/dashboard/overview', definition: '/dashboard/:tab?' },
    icon: 'tachometer',
    nestedLinks: [
      { name: 'Overview', link: { path: '/dashboard/overview' } },
      { name: 'Alarms', link: { path: '/dashboard/alarms' } },
    ],
  },
  {
    name: 'Clusters',
    link: {
      path: '/clusters/managed',
      definition: '/clusters/:tab?/:id?',
    },
    icon: 'dharmachakra',
    nestedLinks: [
      // {
      //   name: 'Overview',
      //   link: {
      //     path: '/clusters/overview',
      //   },
      // },
      // {
      //   name: 'CAPI',
      //   link: { path: '/clusters/capi' },
      // },
      // { name: 'Legacy', link: { path: '/clusters/legacy' } },
      { name: 'Managed', link: { path: '/clusters/managed' } },
      {
        name: 'Imported',
        requiredFeatures: isDecco,
        link: { path: '/clusters/imported' },
      },
    ],
  },
  {
    name: 'Nodes',
    link: { path: '/nodes', definition: '/nodes/:tab?' },
    icon: 'server',
    nestedLinks: [
      {
        name: 'Nodes',
        link: {
          path: '/nodes',
        },
      },
      {
        name: 'EKS Nodes',
        link: { path: '/eks-nodes' },
      },
    ],
  },
  {
    name: 'Cloud Providers',
    link: { path: '/cloud-providers/aws', definition: '/cloud-providers/:tab?' },
    icon: 'clouds',
    nestedLinks: [
      { name: 'AWS', link: { path: '/cloud-providers/aws' } },
      {
        name: 'Azure',
        link: { path: '/cloud-providers/azure' },
      },
      { name: 'Google', link: { path: '/cloud-providers/google' } },
    ],
  },
  // {
  //   name: 'Rbac Profiles',
  //   icon: 'users-cog',
  //   requiredRoles: 'admin',
  //   requiredFeatures: isDecco,
  //   link: { path: '/rbac-profiles/profiles', definition: '/rbac-profiles/:page?' },
  //   nestedLinks: [
  //     {
  //       name: 'Profiles',
  //       link: { path: '/rbac-profiles/profiles' },
  //     },
  //     { name: 'Drift Analytics', link: { path: '/rbac-profiles/drift' } },
  //   ],
  // },
  {
    name: 'API Access',
    icon: 'key',
    link: { path: '/api-access/api-services', definition: '/api-access/:tab' },
    nestedLinks: [
      { name: 'API Services', link: { path: '/api-access/api-services' } },
      { name: 'Kubeconfig', link: { path: '/api-access/kubeconfig' } },
      { name: 'Terraform', link: { path: '/api-access/terraform' } },
    ],
  },
  {
    name: 'Help',
    icon: 'question-circle',
    link: { path: '/help/support', definition: '/help/:tab' },
    isBottomLink: true,
  },
])

export default infrastructurePlugin
