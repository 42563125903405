import React, { useState } from 'react'
import Text from 'core/elements/Text'
import DisableAddonDialog from '../../../cluster-addons/edit-cluster-page/DisableAddonDialog'

export default function EnableAddonButton({ className, addon, cluster, toggleMenu }) {
  const [showDialog, setShowDialog] = useState(false)

  return (
    <>
      <Text
        className={className}
        variant="body2"
        onClick={() => {
          setShowDialog(true)
          toggleMenu()
        }}
      >
        Disable
      </Text>
      {showDialog && (
        <DisableAddonDialog
          addon={addon}
          open
          onClose={() => setShowDialog(false)}
          cluster={cluster}
        />
      )}
    </>
  )
}
