import React from 'react'
import { compareVersions } from 'k8s/util/helpers'
import { MetalLbAddonLayer2Field } from '../MetalLbField'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'

// Note: prop is called base64EncMetallbConfig, but the config the UI provides
// should not be base64 encoded
export default function MetalLbParamFields({ setFieldValue, addonVersion = undefined }) {
  const isUpdatedVersion = compareVersions(addonVersion, '0.9.7') >= 0
  const showConfigField = addonVersion && isUpdatedVersion
  return (
    <>
      <MetalLbAddonLayer2Field />
      {showConfigField && (
        <CodeMirror
          id="base64EncMetallbConfig"
          label="MetalLB Config YAML"
          onChange={setFieldValue('base64EncMetallbConfig')}
        />
      )}
    </>
  )
}
