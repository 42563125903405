import React, { useContext, useEffect } from 'react'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { handleSetUpgradeStrategy } from '../UpgradeClusterPage'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import Text from 'core/elements/Text'
import { IconInfo } from 'core/components/validatedForm/Info'
import { WizardContext } from 'core/components/wizard/Wizard'
import Input from 'core/elements/input/Input'
import { requiredValidator } from 'core/utils/fieldValidators'

const PercentageClusterUpgradeField = ({ wizardContext, setWizardContext }) => (
  <CheckboxField
    id="percentageClusterUpgrade"
    label="Percentage"
    disabled={!!wizardContext.upgradingTo}
    onChange={(value) =>
      setWizardContext(handleSetUpgradeStrategy(value, 'percentageClusterUpgrade'))
    }
    value={wizardContext?.percentageClusterUpgrade}
    info={`
      The specified percent of worker nodes will be upgraded in parallel.
      Ex. Out of 100 cluster nodes 10% would upgrade 10 nodes in parallel, then the next 10.
    `}
  />
)

export const PercentageClusterUpgradeAddonField = ({ values, setValues, defineField }) => {
  const {
    wizardContext = values,
    setWizardContext = setValues,
  }: { wizardContext: any; setWizardContext: any } = useContext(WizardContext) as any

  // this needs to be done as 'batchUpgradePercent' is not getting  added
  //automatically as a field to the ValidatedForm
  useEffect(() => {
    defineField('batchUpgradePercent')({ validations: [requiredValidator] })
    if (!wizardContext?.batchUpgradePercent) {
      setWizardContext({ batchUpgradePercent: 10 })
    }
  }, [])

  return (
    <FormFieldSection title="Percentage Configuration">
      <IconInfo
        icon="info-circle"
        title="Specify the percentage of nodes to upgrade in parallel."
        spacer={false}
      >
        <Text variant="body2">
          <b>Cluster : </b>
          {values?.name}
        </Text>
        <Text variant="body2">
          <b>Total Worker Nodes : </b>
          {values?.workerNodes?.length}
        </Text>
      </IconInfo>
      <Input
        type="number"
        min={0}
        max={100}
        step={10}
        id="batchUpgradePercent"
        label="Percentage of Worker nodes to upgrade in parallel"
        placeholder="Specify % of nodes to upgrade in parallel"
        value={wizardContext?.batchUpgradePercent}
        onChange={(event) =>
          setWizardContext({ batchUpgradePercent: parseInt(event?.target.value) })
        }
        required
      />
    </FormFieldSection>
  )
}

export default PercentageClusterUpgradeField
