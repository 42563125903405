import React from 'react'
import TextField from 'core/components/validatedForm/TextField'
import { compareVersions } from 'k8s/util/helpers'

export default function MonitoringParamFields({
  values,
  setFieldValue,
  showExtraParams = true,
  setWizardContext = undefined,
  wizardContext = undefined,
}) {
  const isOlderClusterVersion = compareVersions(values.kubeRoleVersion, '1.21') < 0
  return (
    <>
      <TextField
        key="retentionTime"
        id="retentionTime"
        label="Retention Time (days)"
        initialValue={wizardContext?.retentionTime || values?.retentionTime || 7}
        onChange={(value) => {
          setFieldValue && setFieldValue('retentionTime')(value)
          setWizardContext && setWizardContext({ retentionTime: value })
        }}
        type="number"
        min="0"
        required
      />
      {showExtraParams && !isOlderClusterVersion && (
        <>
          <TextField
            key="storageClassName"
            id="storageClassName"
            label="Storage Class Name"
            onChange={(value) => {
              setFieldValue && setFieldValue('storageClassName')(value)
              setWizardContext && setWizardContext({ storageClassName: value })
            }}
          />
          <TextField
            key="pvcSize"
            id="pvcSize"
            label="PVC Size (Gi)"
            onChange={(value) => {
              setFieldValue && setFieldValue('pvcSize')(value)
              setWizardContext && setWizardContext({ pvcSize: value })
            }}
            type="number"
            min="0"
          />
        </>
      )}
    </>
  )
}
