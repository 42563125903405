import React, { useEffect, useState } from 'react'
import TextField from 'core/components/validatedForm/TextField'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import { compareVersions } from 'k8s/util/helpers'
import useToggler from 'core/hooks/useToggler'
import ToggleSwitch from 'core/elements/ToggleSwitch'

export default function CoreDnsParamFields({
  setFieldValue,
  addonVersion = undefined,
  values,
  setWizardContext = undefined,
  wizardContext = undefined,
}) {
  const isUpdatedVersion = addonVersion === undefined || compareVersions(addonVersion, '1.8.0') >= 0
  const [showEntireConfigField, toggleEntireConfigField] = useToggler()
  const [initialValueSet, setInitialValue] = useState(false)

  useEffect(() => {
    // base64EncEntireDnsConfig, if specified in the existing addon, will be undefined at first.
    // When the value is finally retrieved from the API, show the config field first
    if (initialValueSet || !values.base64EncEntireDnsConfig || showEntireConfigField) return
    toggleEntireConfigField()
    setInitialValue(true)
  }, [initialValueSet, values.base64EncEntireDnsConfig, showEntireConfigField])

  useEffect(() => {
    if (setWizardContext) {
      setWizardContext({ useEntireCoreDnsConfig: !!showEntireConfigField })
    }
  }, [showEntireConfigField, setWizardContext])

  return (
    <>
      {isUpdatedVersion && (
        <>
          <ToggleSwitch
            active={showEntireConfigField}
            onClick={toggleEntireConfigField}
            label="Specify Entire DNS Config"
          />
          {showEntireConfigField && (
            <CodeMirror
              id="base64EncEntireDnsConfig"
              value={wizardContext?.base64EncEntireDnsConfig || values?.base64EncEntireDnsConfig}
              label="Entire DNS Config"
              onChange={(value) => {
                setFieldValue('base64EncEntireDnsConfig')(value)
                setWizardContext && setWizardContext({ base64EncEntireDnsConfig: value })
              }}
            />
          )}
        </>
      )}
      {!showEntireConfigField && (
        <>
          <TextField
            key="dnsMemoryLimit"
            id="dnsMemoryLimit"
            label="DNS Memory Limit (Mi)"
            initialValue={wizardContext?.dnsMemoryLimit || values?.dnsMemoryLimit || 170}
            onChange={(value) => {
              setFieldValue('dnsMemoryLimit')(value)
              setWizardContext && setWizardContext({ dnsMemoryLimit: value })
            }}
            type="number"
            min="0"
            required
          />
          <TextField
            key="dnsDomain"
            id="dnsDomain"
            label="DNS Domain"
            initialValue={wizardContext?.dnsDomain || values?.dnsDomain || 'cluster.local'}
            onChange={(value) => {
              setFieldValue('dnsDomain')(value)
              setWizardContext && setWizardContext({ dnsDomain: value })
            }}
          />
          <CodeMirror
            id="base64EncAdditionalDnsConfig"
            value={
              wizardContext?.base64EncAdditionalDnsConfig || values?.base64EncAdditionalDnsConfig
            }
            label="Additional DNS Config"
            onChange={(value) => {
              setFieldValue('base64EncAdditionalDnsConfig')(value)
              setWizardContext && setWizardContext({ base64EncAdditionalDnsConfig: value })
            }}
          />
        </>
      )}
    </>
  )
}
