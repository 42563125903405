/* eslint-disable @typescript-eslint/no-empty-interface */
import { ClusterElement, EtcdBackup } from 'api-client/qbert.model'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'

export enum ClusterTypes {
  Normal = 'normal',
  Imported = 'imported',
  Capi = 'capi',
}

export enum CapiVpcTypes {
  SelfManaged = 'self-managed',
  PmkManaged = 'pmk-managed',
}
export enum ClusterCreateTypes {
  OneClick = 'one-click',
  Custom = 'custom',
  Capi = 'capi',
  EksCapi = 'eks-capi',
  SingleMaster = 'single-master',
  MultiMaster = 'multi-master',
}

export enum ClusterCreateTypeNames {
  'one-click' = 'One-Click',
  'custom' = 'Advanced Cluster Setup',
  'capi' = 'New Generation AWS Cluster',
  'eks-capi' = 'New Generation EKS Cluster',
  'single-master' = 'Single Master',
  'multi-master' = 'Multi-Master',
  'standard-cluster' = 'Standard Cluster',
}

export enum BareOsRequiredNodes {
  'one-click' = 1,
  'single-master' = 1,
  'multi-master' = 2,
}

export type HealthStatus = 'healthy' | 'partially_healthy' | 'unhealthy' | 'unknown' | 'upgrading'

export interface IClusterAction extends ClusterElement {}

export type TransientStatus = 'creating' | 'deleting' | 'updating' | 'upgrading' | 'converging'

export type ConnectionStatus = 'connected' | 'partially_connected' | 'disconnected' | 'upgrading'

export type ApiServerStatus = 'online' | 'offline' | 'degraded'

export enum IClusterEditPageTabs {
  General = 'general',
  ApiFlags = 'apiFlags',
  Addons = 'addons',
}

export interface IResourceUsage {
  compute: any
  memory: any
  disk: any
  grafanaLink: string
}

export interface ClusterSelector extends IClusterAction {
  uuid: string
  hasVpn: boolean
  hasLoadBalancer: boolean
  etcdBackupEnabled: boolean
  canUpgrade: boolean
  upgradingTo?: string
  version: string
  cloudProviderType: string
  usage: IResourceUsage
  nodes: INodesSelector[]
  masterNodes: INodesSelector[]
  workerNodes: INodesSelector[]
  healthyMasterNodes: INodesSelector[]
  healthyWorkerNodes: INodesSelector[]
  masterNodesHealthStatus: HealthStatus | TransientStatus
  workerNodesHealthStatus: HealthStatus | TransientStatus
  connectionStatus: ConnectionStatus | TransientStatus
  healthStatus: HealthStatus | TransientStatus
  taskStatus: string
  etcdBackup: EtcdBackup
  taskError: string | null
  hasMasterNode: boolean
  hasPrometheus: boolean
  hasKubevirt: boolean
  hasAdvancedNetworking: boolean
  highlyAvailable: boolean
  endpoint: string
  links: {
    dashboard: string | null
    kubeconfig: {
      cluster: IClusterAction
    } | null
    cli: {
      host: string
      cluster: IClusterAction
    } | null
  }
  clusterType: ClusterTypes.Normal
  creationTimestamp: string
  upgrading: boolean
  upgradeFailed: boolean
  kubevirtAddon?: any
  healthStatusLabel?: string
}

export type IClusterStatus =
  | 'ok'
  | 'pause'
  | 'fail'
  | 'unknown'
  | 'error'
  | 'loading'
  | 'upgrade'
  | 'pending'
  | 'degraded'

export interface EventsResponse {
  kind: string
  apiVersion: string
  metadata: EventsResponseMetadata
  items: EventsItem[]
}

export interface EventsItem {
  metadata: ItemMetadata
  eventTime: null | string
  reason: string
  regarding: Regarding
  note: string
  type: string
  deprecatedSource: DeprecatedSource
  deprecatedFirstTimestamp: string
  deprecatedLastTimestamp: string
  deprecatedCount?: number
}

interface DeprecatedSource {
  component?: string
  host?: string
}
interface ItemMetadata {
  name: string
  namespace: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
}

interface Regarding {
  kind: string
  namespace: string
  name: string
  uid: string
  apiVersion: string
  resourceVersion: string
  fieldPath?: string
}

interface EventsResponseMetadata {
  resourceVersion: string
}

export interface IEventSelector extends EventsItem {
  id: string
  name: string
}

export enum UpgradeTypes {
  Minor = 'minor',
  Patch = 'patch',
}

export enum ApiServerLoadBalancerTypes {
  AwsClassicLoadBalancer = 'awsClassicLoadBalancer',
  AwsNetworkLoadBalancer = 'awsNetworkLoadBalancer',
}
export enum ApiServerLoadBalancerTypeLabels {
  'awsClassicLoadBalancer' = 'AWS Classic Load Balancer',
  'awsNetworkLoadBalancer' = 'AWS Network Load Balancer',
}

export enum SchemeTypes {
  Internal = 'internal',
  InternetFacing = 'internet-facing',
}
export enum SchemeTypeLabels {
  'internal' = 'Internal',
  'internet-facing' = 'Internet facing',
}
export interface Subnet {
  id?: string
  routeTabelId?: string
  natGatewayId?: string
  isPublic?: boolean
  availabilityZone?: string
  cidrBlock?: string
}

export interface CidrBlock {
  base: string
  mask: string
  broadcast: string
  size: number
  first: string
  last: string
}
